mark, ais-highlight-0000000000 {
  background-color: #f4cccc;
}

a {
  text-decoration: underline;
  color: inherit
}

@media only screen and (max-width: 1400px) {
  html {
    font-size: 14px;
  }
}
@media only screen and (max-width: 1200px) {
  html {
    font-size: 12px;
  }
}
@media only screen and (max-width: 1000px) {
  html {
    font-size: 11px;
  }
}